import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type Argument = {
  __typename?: 'Argument';
  default_value?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  options: Array<Maybe<Option>>;
  order: Scalars['Int'];
  row: Scalars['Int'];
  schema: Schema;
};

export type ArgumentInput = {
  default_value?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<OptionInput>>;
  order?: Maybe<Scalars['Int']>;
  row?: Maybe<Scalars['Int']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  access_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CameraStatus = {
  __typename?: 'CameraStatus';
  isConnected: Scalars['Boolean'];
  status: Scalars['String'];
};

export type CreateReservationInput = {
  device_id: Scalars['ID'];
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CreateRoleInput = {
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['ID']>>;
};

export type CreateSchemaInput = {
  arguments?: Maybe<Array<ArgumentInput>>;
  device_type_id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Upload']>;
  schema?: Maybe<Scalars['Upload']>;
  software_id: Scalars['ID'];
  type: Scalars['String'];
};

export type CreateServerInput = {
  api_domain: Scalars['String'];
  enabled: Scalars['Boolean'];
  ip_address: Scalars['String'];
  name: Scalars['String'];
  production: Scalars['Boolean'];
  websocket_port: Scalars['Int'];
};

export type DateTimeRange = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type Device = {
  __typename?: 'Device';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  deviceType: DeviceType;
  experiment: Array<Experiment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  production: Scalars['Boolean'];
  remote_id: Scalars['ID'];
  reservations: Array<Reservation>;
  server: Server;
  software: Array<Software>;
  updated_at: Scalars['DateTime'];
  userExperiment: Array<UserExperiment>;
};


export type DeviceReservationsArgs = {
  end?: Maybe<DateTimeRange>;
  start?: Maybe<DateTimeRange>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  created_at: Scalars['DateTime'];
  devices: Array<Device>;
  experiment: Array<Experiment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};


export type DeviceTypeDevicesArgs = {
  trashed?: Maybe<Trashed>;
};


export type DeviceTypeExperimentArgs = {
  trashed?: Maybe<Trashed>;
};

export type Experiment = {
  __typename?: 'Experiment';
  commands: Array<Maybe<Scalars['String']>>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Device>;
  deviceType: DeviceType;
  experiment_commands: Array<Maybe<ExperimentCommand>>;
  has_schema?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  output_arguments: Array<ExperimentOutputArguments>;
  server?: Maybe<Server>;
  software: Software;
  updated_at: Scalars['DateTime'];
};

export type ExperimentArgument = {
  __typename?: 'ExperimentArgument';
  default_value?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<ExperimentOption>>;
  order: Scalars['Int'];
  row: Scalars['Int'];
};

export type ExperimentCommand = {
  __typename?: 'ExperimentCommand';
  arguments: Array<ExperimentArgument>;
  name: Scalars['String'];
};

export type ExperimentOption = {
  __typename?: 'ExperimentOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ExperimentOutputArguments = {
  __typename?: 'ExperimentOutputArguments';
  defaultVisibilityFor?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type LdapLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createReservation: Reservation;
  createRole: Role;
  createSchema: Schema;
  createServer: Server;
  deleteReservation: Reservation;
  deleteRole: Role;
  deleteSchema: Schema;
  deleteServer: Server;
  deleteUser: User;
  deleteUserExperiment: UserExperiment;
  forgotPassword: ForgotPasswordResponse;
  ldapLogin: AuthPayload;
  login: AuthPayload;
  logout: LogoutResponse;
  queueUserExperiment: UserExperiment;
  refreshToken: RefreshTokenPayload;
  register: RegisterResponse;
  restoreSchema: Schema;
  restoreServer: Server;
  restoreUser: User;
  restoreUserExperiment: UserExperiment;
  runUserExperiment?: Maybe<UserExperiment>;
  socialLogin: AuthPayload;
  startVideoStream: VideoStreamStatus;
  stopVideoStream: StopVideoStreamStatus;
  syncAllServers: Array<Server>;
  syncServer: Server;
  updateForgottenPassword: ForgotPasswordResponse;
  updatePassword: UpdatePasswordResponse;
  updateProfile: User;
  updateReservation: Reservation;
  updateRole: Role;
  updateSchema: Schema;
  updateServer: Server;
  updateUser: User;
  updateUserExperiment: UserExperiment;
  verifyEmail: AuthPayload;
};


export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateSchemaArgs = {
  input: CreateSchemaInput;
};


export type MutationCreateServerArgs = {
  input: CreateServerInput;
};


export type MutationDeleteReservationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSchemaArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserExperimentArgs = {
  id: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationLdapLoginArgs = {
  input?: Maybe<LdapLoginInput>;
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationQueueUserExperimentArgs = {
  input: QueueUserExperimentInput;
};


export type MutationRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>;
};


export type MutationRegisterArgs = {
  input?: Maybe<RegisterInput>;
};


export type MutationRestoreSchemaArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreServerArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreUserArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreUserExperimentArgs = {
  id: Scalars['ID'];
};


export type MutationRunUserExperimentArgs = {
  input: RunUserExperimentInput;
};


export type MutationSocialLoginArgs = {
  input: SocialLoginInput;
};


export type MutationStartVideoStreamArgs = {
  device_id: Scalars['ID'];
};


export type MutationStopVideoStreamArgs = {
  device_id: Scalars['ID'];
};


export type MutationSyncAllServersArgs = {
  trashed?: Maybe<Trashed>;
};


export type MutationSyncServerArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: Maybe<NewPasswordWithCodeInput>;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePassword;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateReservationArgs = {
  input: UpdateReservationInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateSchemaArgs = {
  input: UpdateSchemaInput;
};


export type MutationUpdateServerArgs = {
  input: UpdateServerInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserExperimentArgs = {
  input?: Maybe<UpdateUserExperimentInput>;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  argument: Argument;
  id: Scalars['ID'];
  name: Scalars['String'];
  output_value: Scalars['String'];
  value: Scalars['String'];
};

export type OptionInput = {
  name: Scalars['String'];
  output_value: Scalars['String'];
  value: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  created_at: Scalars['DateTime'];
  guard_name: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  availableSchemaTypes: Array<Scalars['String']>;
  cameraStatus: CameraStatus;
  deviceTypes: Array<DeviceType>;
  devices: Array<Device>;
  experiment?: Maybe<Experiment>;
  experiments: Array<Experiment>;
  me?: Maybe<User>;
  permissions: Array<Permission>;
  reservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
  reservationsCurrent: Array<Reservation>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  schema?: Maybe<Schema>;
  schemas: Array<Schema>;
  server?: Maybe<Server>;
  servers: Array<Server>;
  software: Array<Software>;
  user?: Maybe<User>;
  userExperiment?: Maybe<UserExperiment>;
  userExperimentCurrent?: Maybe<UserExperiment>;
  userExperiments?: Maybe<UserExperimentPaginator>;
  users?: Maybe<UserPaginator>;
  videoStreamStatus: VideoStreamStatus;
};


export type QueryCameraStatusArgs = {
  device_id: Scalars['ID'];
};


export type QueryExperimentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryExperimentsArgs = {
  device_id?: Maybe<Scalars['ID']>;
  server_id?: Maybe<Scalars['ID']>;
};


export type QueryReservationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySchemaArgs = {
  id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
};


export type QuerySchemasArgs = {
  device_type_id?: Maybe<Scalars['ID']>;
  software_id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
};


export type QueryServerArgs = {
  id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
};


export type QueryServersArgs = {
  enabled?: Maybe<Scalars['Boolean']>;
  production?: Maybe<Scalars['Boolean']>;
  trashed?: Maybe<Trashed>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
};


export type QueryUserExperimentArgs = {
  id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
};


export type QueryUserExperimentsArgs = {
  first: Scalars['Int'];
  onlyMine?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<QueryUserExperimentsOrderByOrderByClause>>;
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryUsersArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryVideoStreamStatusArgs = {
  device_id: Scalars['ID'];
};

/** Allowed column names for Query.userExperiments.orderBy. */
export enum QueryUserExperimentsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Filled = 'FILLED'
}

/** Order by clause for Query.userExperiments.orderBy. */
export type QueryUserExperimentsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryUserExperimentsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type QueueUserExperimentInput = {
  experiment_id: Scalars['ID'];
  input: Array<UserExperimentArgsInput>;
  schema_id?: Maybe<Scalars['ID']>;
  software_id: Scalars['ID'];
};

export type RefreshTokenInput = {
  refresh_token?: Maybe<Scalars['String']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  status: RegisterStatuses;
  tokens?: Maybe<AuthPayload>;
};

export enum RegisterStatuses {
  MustVerifyEmail = 'MUST_VERIFY_EMAIL',
  Success = 'SUCCESS'
}

export type Reservation = {
  __typename?: 'Reservation';
  created_at: Scalars['DateTime'];
  device: Device;
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
  user: User;
};

export type Role = {
  __typename?: 'Role';
  created_at: Scalars['DateTime'];
  guard_name: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  updated_at: Scalars['DateTime'];
};

export type RunUserExperimentInput = {
  experiment_id: Scalars['ID'];
  input: Array<UserExperimentArgsInput>;
  note?: Maybe<Scalars['String']>;
  schema_id?: Maybe<Scalars['ID']>;
  software_id: Scalars['ID'];
  user_experiment_id?: Maybe<Scalars['ID']>;
};

export type Schema = {
  __typename?: 'Schema';
  arguments: Array<Argument>;
  availableTypes: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  deviceType: DeviceType;
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  software: Software;
  type: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type Server = {
  __typename?: 'Server';
  api_domain: Scalars['String'];
  available: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  ip_address: Scalars['String'];
  name: Scalars['String'];
  production: Scalars['Boolean'];
  updated_at: Scalars['DateTime'];
  websocket_port: Scalars['Int'];
};


export type ServerDevicesArgs = {
  trashed?: Maybe<Trashed>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export type SocialLoginInput = {
  provider: Scalars['String'];
  token: Scalars['String'];
};

export type Software = {
  __typename?: 'Software';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StopVideoStreamStatus = {
  __typename?: 'StopVideoStreamStatus';
  isStopped: Scalars['Boolean'];
  status: Scalars['String'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdatePassword = {
  old_password: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type UpdateProfileInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateReservationInput = {
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateSchemaInput = {
  arguments?: Maybe<Array<ArgumentInput>>;
  device_type_id: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Upload']>;
  schema?: Maybe<Scalars['Upload']>;
  software_id: Scalars['ID'];
  type: Scalars['String'];
};

export type UpdateServerInput = {
  api_domain: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  ip_address: Scalars['String'];
  name: Scalars['String'];
  production: Scalars['Boolean'];
  websocket_port: Scalars['Int'];
};

export type UpdateUserExperimentInput = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = {
  __typename?: 'User';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissionsList: Array<Scalars['String']>;
  roles: Array<Role>;
  updated_at: Scalars['DateTime'];
};

export type UserExperiment = {
  __typename?: 'UserExperiment';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Device>;
  evaluation?: Maybe<Array<Array<UserExperimentEvaluation>>>;
  experiment: Experiment;
  filled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  input: Array<UserExperimentInput>;
  note?: Maybe<Scalars['String']>;
  output?: Maybe<Array<UserExperimentOutput>>;
  remote_id?: Maybe<Scalars['ID']>;
  result?: Maybe<Scalars['String']>;
  sampling_rate: Scalars['Int'];
  schema?: Maybe<Schema>;
  simulation_time: Scalars['Int'];
  updated_at: Scalars['DateTime'];
  user: User;
};


export type UserExperimentDeviceArgs = {
  trashed?: Maybe<Trashed>;
};


export type UserExperimentExperimentArgs = {
  trashed?: Maybe<Trashed>;
};


export type UserExperimentSchemaArgs = {
  trashed?: Maybe<Trashed>;
};

export type UserExperimentArg = {
  __typename?: 'UserExperimentArg';
  formatted_value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UserExperimentArgInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UserExperimentArgsInput = {
  input: Array<UserExperimentArgInput>;
  script_name: Scalars['String'];
};

export type UserExperimentEvaluation = {
  __typename?: 'UserExperimentEvaluation';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type UserExperimentInput = {
  __typename?: 'UserExperimentInput';
  input: Array<Array<UserExperimentArg>>;
  script_name: Scalars['String'];
};

export type UserExperimentOutput = {
  __typename?: 'UserExperimentOutput';
  data: Array<Scalars['Float']>;
  name: Scalars['String'];
};

/** A paginated list of UserExperiment items. */
export type UserExperimentPaginator = {
  __typename?: 'UserExperimentPaginator';
  /** A list of UserExperiment items. */
  data: Array<UserExperiment>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VerifyEmailInput = {
  token: Scalars['String'];
};

export type VideoStreamStatus = {
  __typename?: 'VideoStreamStatus';
  isRunning: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
};

export type PaginatorInfoFragment = { __typename?: 'PaginatorInfo', count: number, currentPage: number, hasMorePages: boolean, lastPage: number, perPage: number, total: number };

export type ReservationsCurrentFragment = { __typename?: 'Reservation', start: any, end: any, device: { __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, server: { __typename?: 'Server', id: string, name: string }, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> } };

export type DeviceWithServerFragment = { __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, server: { __typename?: 'Server', id: string, name: string }, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> };

export type ExperimentSchemaFragment = { __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> };

export type ExperimentBasicFragment = { __typename?: 'Experiment', id: string, name: string, has_schema?: Maybe<boolean>, commands: Array<Maybe<string>>, software: { __typename?: 'Software', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', id: string, name: string }>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, server?: Maybe<{ __typename?: 'Server', id: string, api_domain: string }>, experiment_commands: Array<Maybe<{ __typename?: 'ExperimentCommand', name: string, arguments: Array<{ __typename?: 'ExperimentArgument', name: string, label: string, row: number, order: number, default_value?: Maybe<string>, options?: Maybe<Array<{ __typename?: 'ExperimentOption', name: string, value: string }>> }> }>> };

export type UserExperimentDashboardFragment = { __typename?: 'UserExperiment', id: string, sampling_rate: number, simulation_time: number, filled?: Maybe<boolean>, created_at: any, updated_at: any, experiment: { __typename?: 'Experiment', id: string, device?: Maybe<{ __typename?: 'Device', name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string } }>, server?: Maybe<{ __typename?: 'Server', ip_address: string, api_domain: string, websocket_port: number }>, software: { __typename?: 'Software', id: string, name: string } }, schema?: Maybe<{ __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> };

export type CameraStatusFragment = { __typename?: 'CameraStatus', isConnected: boolean, status: string };

export type VideoStreamStatusFragment = { __typename?: 'VideoStreamStatus', isRunning: boolean, status?: Maybe<string> };

export type StopVideoStreamStatusFragment = { __typename?: 'StopVideoStreamStatus', isStopped: boolean, status: string };

export type RunUserExperimentMutationVariables = Exact<{
  runUserExperimentInput: RunUserExperimentInput;
}>;


export type RunUserExperimentMutation = { __typename?: 'Mutation', runUserExperiment?: Maybe<{ __typename?: 'UserExperiment', id: string, sampling_rate: number, simulation_time: number, filled?: Maybe<boolean>, created_at: any, updated_at: any, experiment: { __typename?: 'Experiment', id: string, device?: Maybe<{ __typename?: 'Device', name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string } }>, server?: Maybe<{ __typename?: 'Server', ip_address: string, api_domain: string, websocket_port: number }>, software: { __typename?: 'Software', id: string, name: string } }, schema?: Maybe<{ __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> }> };

export type QueueUserExperimentMutationVariables = Exact<{
  queueUserExperimentInput: QueueUserExperimentInput;
}>;


export type QueueUserExperimentMutation = { __typename?: 'Mutation', queueUserExperiment: { __typename?: 'UserExperiment', id: string, sampling_rate: number, simulation_time: number, filled?: Maybe<boolean>, created_at: any, updated_at: any, experiment: { __typename?: 'Experiment', id: string, device?: Maybe<{ __typename?: 'Device', name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string } }>, server?: Maybe<{ __typename?: 'Server', ip_address: string, api_domain: string, websocket_port: number }>, software: { __typename?: 'Software', id: string, name: string } }, schema?: Maybe<{ __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> } };

export type StartVideoStreamMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type StartVideoStreamMutation = { __typename?: 'Mutation', startVideoStream: { __typename?: 'VideoStreamStatus', isRunning: boolean, status?: Maybe<string> } };

export type StopVideoStreamMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type StopVideoStreamMutation = { __typename?: 'Mutation', stopVideoStream: { __typename?: 'StopVideoStreamStatus', isStopped: boolean, status: string } };

export type ReservationsCurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type ReservationsCurrentQuery = { __typename?: 'Query', reservationsCurrent: Array<{ __typename?: 'Reservation', start: any, end: any, device: { __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, server: { __typename?: 'Server', id: string, name: string }, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> } }> };

export type ExperimentsQueryVariables = Exact<{
  serverId?: Maybe<Scalars['ID']>;
  deviceId?: Maybe<Scalars['ID']>;
}>;


export type ExperimentsQuery = { __typename?: 'Query', experiments: Array<{ __typename?: 'Experiment', id: string, name: string, has_schema?: Maybe<boolean>, commands: Array<Maybe<string>>, software: { __typename?: 'Software', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', id: string, name: string }>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, server?: Maybe<{ __typename?: 'Server', id: string, api_domain: string }>, experiment_commands: Array<Maybe<{ __typename?: 'ExperimentCommand', name: string, arguments: Array<{ __typename?: 'ExperimentArgument', name: string, label: string, row: number, order: number, default_value?: Maybe<string>, options?: Maybe<Array<{ __typename?: 'ExperimentOption', name: string, value: string }>> }> }>> }> };

export type ExperimentSchemasQueryVariables = Exact<{
  deviceTypeId: Scalars['ID'];
  softwareId: Scalars['ID'];
}>;


export type ExperimentSchemasQuery = { __typename?: 'Query', schemas: Array<{ __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> };

export type UserExperimentCurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type UserExperimentCurrentQuery = { __typename?: 'Query', userExperimentCurrent?: Maybe<{ __typename?: 'UserExperiment', id: string, sampling_rate: number, simulation_time: number, filled?: Maybe<boolean>, created_at: any, updated_at: any, experiment: { __typename?: 'Experiment', id: string, device?: Maybe<{ __typename?: 'Device', name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string } }>, server?: Maybe<{ __typename?: 'Server', ip_address: string, api_domain: string, websocket_port: number }>, software: { __typename?: 'Software', id: string, name: string } }, schema?: Maybe<{ __typename?: 'Schema', id: string, name: string, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> }> };

export type CameraStatusQueryVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type CameraStatusQuery = { __typename?: 'Query', cameraStatus: { __typename?: 'CameraStatus', isConnected: boolean, status: string } };

export type VideoStreamStatusQueryVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type VideoStreamStatusQuery = { __typename?: 'Query', videoStreamStatus: { __typename?: 'VideoStreamStatus', isRunning: boolean, status?: Maybe<string> } };

export type DeviceWithReservationsFragment = { __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, reservations: Array<{ __typename?: 'Reservation', id: string, title: string, start: any, end: any, user: { __typename?: 'User', id: string } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> };

export type ReservationBasicFragment = { __typename?: 'Reservation', id: string, title: string, start: any, end: any, user: { __typename?: 'User', id: string } };

export type CreateReservationMutationVariables = Exact<{
  createReservationInput: CreateReservationInput;
}>;


export type CreateReservationMutation = { __typename?: 'Mutation', createReservation: { __typename?: 'Reservation', id: string } };

export type UpdateReservationMutationVariables = Exact<{
  updateReservationInput: UpdateReservationInput;
}>;


export type UpdateReservationMutation = { __typename?: 'Mutation', updateReservation: { __typename?: 'Reservation', id: string } };

export type DeleteReservationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReservationMutation = { __typename?: 'Mutation', deleteReservation: { __typename?: 'Reservation', id: string } };

export type DevicesQueryVariables = Exact<{
  reservationStart?: Maybe<DateTimeRange>;
}>;


export type DevicesQuery = { __typename?: 'Query', devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, reservations: Array<{ __typename?: 'Reservation', id: string, title: string, start: any, end: any, user: { __typename?: 'User', id: string } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> };

export type ServersWithDevicesQueryVariables = Exact<{
  production?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  reservationStart?: Maybe<DateTimeRange>;
}>;


export type ServersWithDevicesQuery = { __typename?: 'Query', servers: Array<{ __typename?: 'Server', production: boolean, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, reservations: Array<{ __typename?: 'Reservation', id: string, title: string, start: any, end: any, user: { __typename?: 'User', id: string } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> }> };

export type RoleBasicFragment = { __typename?: 'Role', id: string, name: string };

export type RoleExtendedFragment = { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> };

export type PermissionBasicFragment = { __typename?: 'Permission', id: string, name: string };

export type CreateRoleMutationVariables = Exact<{
  createRoleInput: CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> } };

export type UpdateRoleMutationVariables = Exact<{
  updateRoleInput: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> } };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'Role', id: string } };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, name: string }> };

export type RoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleQuery = { __typename?: 'Query', role?: Maybe<{ __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> }> };

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = { __typename?: 'Query', permissions: Array<{ __typename?: 'Permission', id: string, name: string }> };

export type SchemaBasicFragment = { __typename?: 'Schema', id: string, name: string, schema?: Maybe<string>, preview?: Maybe<string>, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string } };

export type SchemaExtendedFragment = { __typename?: 'Schema', id: string, name: string, type: string, availableTypes: Array<string>, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> };

export type ArgumentBasicFragment = { __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> };

export type CreateSchemaMutationVariables = Exact<{
  createSchemaInput: CreateSchemaInput;
}>;


export type CreateSchemaMutation = { __typename?: 'Mutation', createSchema: { __typename?: 'Schema', id: string, name: string, type: string, availableTypes: Array<string>, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> } };

export type UpdateSchemaMutationVariables = Exact<{
  updateSchemaInput: UpdateSchemaInput;
}>;


export type UpdateSchemaMutation = { __typename?: 'Mutation', updateSchema: { __typename?: 'Schema', id: string, name: string, type: string, availableTypes: Array<string>, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> } };

export type DeleteSchemaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSchemaMutation = { __typename?: 'Mutation', deleteSchema: { __typename?: 'Schema', id: string } };

export type RestoreSchemaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreSchemaMutation = { __typename?: 'Mutation', restoreSchema: { __typename?: 'Schema', id: string } };

export type SchemasQueryVariables = Exact<{
  trashed?: Maybe<Trashed>;
}>;


export type SchemasQuery = { __typename?: 'Query', schemas: Array<{ __typename?: 'Schema', id: string, name: string, schema?: Maybe<string>, preview?: Maybe<string>, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string } }> };

export type SchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SchemaQuery = { __typename?: 'Query', schema?: Maybe<{ __typename?: 'Schema', id: string, name: string, type: string, availableTypes: Array<string>, note?: Maybe<string>, schema?: Maybe<string>, preview?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: { __typename?: 'Software', id: string, name: string }, arguments: Array<{ __typename?: 'Argument', name: string, label: string, default_value?: Maybe<string>, row: number, order: number, options: Array<Maybe<{ __typename?: 'Option', name: string, value: string, output_value: string }>> }> }> };

export type AvailableSchemaTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableSchemaTypesQuery = { __typename?: 'Query', availableSchemaTypes: Array<string> };

export type DeviceTypesAndSoftwareQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceTypesAndSoftwareQuery = { __typename?: 'Query', deviceTypes: Array<{ __typename?: 'DeviceType', id: string, name: string, experiment: Array<{ __typename?: 'Experiment', output_arguments: Array<{ __typename?: 'ExperimentOutputArguments', name: string }> }> }>, software: Array<{ __typename?: 'Software', id: string, name: string }> };

export type ServerBasicFragment = { __typename?: 'Server', id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> };

export type ServerExtendedFragment = { __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> };

export type DeviceBasicFragment = { __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> };

export type DeviceTypeBasicFragment = { __typename?: 'DeviceType', id: string, name: string };

export type SoftwareBasicFragment = { __typename?: 'Software', id: string, name: string };

export type CreateServerMutationVariables = Exact<{
  createServerInput: CreateServerInput;
  trashedDevices?: Maybe<Trashed>;
}>;


export type CreateServerMutation = { __typename?: 'Mutation', createServer: { __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> } };

export type UpdateServerMutationVariables = Exact<{
  updateServerInput: UpdateServerInput;
  trashedDevices?: Maybe<Trashed>;
}>;


export type UpdateServerMutation = { __typename?: 'Mutation', updateServer: { __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> } };

export type DeleteServerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteServerMutation = { __typename?: 'Mutation', deleteServer: { __typename?: 'Server', id: string } };

export type RestoreServerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreServerMutation = { __typename?: 'Mutation', restoreServer: { __typename?: 'Server', id: string } };

export type RestoreServerExtendedMutationVariables = Exact<{
  id: Scalars['ID'];
  trashedDevices?: Maybe<Trashed>;
}>;


export type RestoreServerExtendedMutation = { __typename?: 'Mutation', restoreServer: { __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> } };

export type SyncAllServersMutationVariables = Exact<{
  trashedServers?: Maybe<Trashed>;
  trashedDevices?: Maybe<Trashed>;
}>;


export type SyncAllServersMutation = { __typename?: 'Mutation', syncAllServers: Array<{ __typename?: 'Server', id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> }> };

export type SyncServerMutationVariables = Exact<{
  id: Scalars['ID'];
  trashedDevices?: Maybe<Trashed>;
}>;


export type SyncServerMutation = { __typename?: 'Mutation', syncServer: { __typename?: 'Server', id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> } };

export type SyncServerExtendedMutationVariables = Exact<{
  id: Scalars['ID'];
  trashedDevices?: Maybe<Trashed>;
}>;


export type SyncServerExtendedMutation = { __typename?: 'Mutation', syncServer: { __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> } };

export type ServersAndDevicesQueryVariables = Exact<{
  trashedServers?: Maybe<Trashed>;
  trashedDevices?: Maybe<Trashed>;
}>;


export type ServersAndDevicesQuery = { __typename?: 'Query', servers: Array<{ __typename?: 'Server', id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> }> };

export type ServerQueryVariables = Exact<{
  id: Scalars['ID'];
  trashedServer?: Maybe<Trashed>;
  trashedDevices?: Maybe<Trashed>;
}>;


export type ServerQuery = { __typename?: 'Query', server?: Maybe<{ __typename?: 'Server', websocket_port: number, id: string, name: string, ip_address: string, api_domain: string, available: boolean, production: boolean, enabled: boolean, deleted_at?: Maybe<any>, devices: Array<{ __typename?: 'Device', id: string, name: string, deleted_at?: Maybe<any>, deviceType: { __typename?: 'DeviceType', id: string, name: string }, software: Array<{ __typename?: 'Software', id: string, name: string }> }> }> };

export type UserExperimentBasicFragment = { __typename?: 'UserExperiment', id: string, filled?: Maybe<boolean>, simulation_time: number, result?: Maybe<string>, created_at: any, deleted_at?: Maybe<any>, user: { __typename?: 'User', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', name: string }>, experiment: { __typename?: 'Experiment', deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string }, output_arguments: Array<{ __typename?: 'ExperimentOutputArguments', name: string, title: string, defaultVisibilityFor?: Maybe<Array<string>> }> }, evaluation?: Maybe<Array<Array<{ __typename?: 'UserExperimentEvaluation', name: string, value: number }>>> };

export type UserExperimentSchemaFragment = { __typename?: 'Schema', name: string, preview?: Maybe<string> };

export type UserExperimentExtendedFragment = { __typename?: 'UserExperiment', note?: Maybe<string>, remote_id?: Maybe<string>, id: string, filled?: Maybe<boolean>, simulation_time: number, result?: Maybe<string>, created_at: any, deleted_at?: Maybe<any>, schema?: Maybe<{ __typename?: 'Schema', name: string, preview?: Maybe<string> }>, input: Array<{ __typename?: 'UserExperimentInput', script_name: string, input: Array<Array<{ __typename?: 'UserExperimentArg', name: string, value: string, formatted_value?: Maybe<string>, label?: Maybe<string> }>> }>, output?: Maybe<Array<{ __typename?: 'UserExperimentOutput', name: string, data: Array<number> }>>, user: { __typename?: 'User', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', name: string }>, experiment: { __typename?: 'Experiment', deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string }, output_arguments: Array<{ __typename?: 'ExperimentOutputArguments', name: string, title: string, defaultVisibilityFor?: Maybe<Array<string>> }> }, evaluation?: Maybe<Array<Array<{ __typename?: 'UserExperimentEvaluation', name: string, value: number }>>> };

export type DeleteUserExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserExperimentMutation = { __typename?: 'Mutation', deleteUserExperiment: { __typename?: 'UserExperiment', id: string } };

export type RestoreUserExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreUserExperimentMutation = { __typename?: 'Mutation', restoreUserExperiment: { __typename?: 'UserExperiment', id: string } };

export type UpdateUserExperimentMutationVariables = Exact<{
  updateUserExperimentInput: UpdateUserExperimentInput;
}>;


export type UpdateUserExperimentMutation = { __typename?: 'Mutation', updateUserExperiment: { __typename?: 'UserExperiment', id: string } };

export type UserExperimentsQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
  onlyMine?: Maybe<Scalars['Boolean']>;
}>;


export type UserExperimentsQuery = { __typename?: 'Query', userExperiments?: Maybe<{ __typename?: 'UserExperimentPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, hasMorePages: boolean, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'UserExperiment', id: string, filled?: Maybe<boolean>, simulation_time: number, result?: Maybe<string>, created_at: any, deleted_at?: Maybe<any>, user: { __typename?: 'User', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', name: string }>, experiment: { __typename?: 'Experiment', deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string }, output_arguments: Array<{ __typename?: 'ExperimentOutputArguments', name: string, title: string, defaultVisibilityFor?: Maybe<Array<string>> }> }, evaluation?: Maybe<Array<Array<{ __typename?: 'UserExperimentEvaluation', name: string, value: number }>>> }> }> };

export type UserExperimentQueryVariables = Exact<{
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
}>;


export type UserExperimentQuery = { __typename?: 'Query', userExperiment?: Maybe<{ __typename?: 'UserExperiment', note?: Maybe<string>, remote_id?: Maybe<string>, id: string, filled?: Maybe<boolean>, simulation_time: number, result?: Maybe<string>, created_at: any, deleted_at?: Maybe<any>, schema?: Maybe<{ __typename?: 'Schema', name: string, preview?: Maybe<string> }>, input: Array<{ __typename?: 'UserExperimentInput', script_name: string, input: Array<Array<{ __typename?: 'UserExperimentArg', name: string, value: string, formatted_value?: Maybe<string>, label?: Maybe<string> }>> }>, output?: Maybe<Array<{ __typename?: 'UserExperimentOutput', name: string, data: Array<number> }>>, user: { __typename?: 'User', id: string, name: string }, device?: Maybe<{ __typename?: 'Device', name: string }>, experiment: { __typename?: 'Experiment', deviceType: { __typename?: 'DeviceType', name: string }, software: { __typename?: 'Software', name: string }, output_arguments: Array<{ __typename?: 'ExperimentOutputArguments', name: string, title: string, defaultVisibilityFor?: Maybe<Array<string>> }> }, evaluation?: Maybe<Array<Array<{ __typename?: 'UserExperimentEvaluation', name: string, value: number }>>> }> };

export type ProfileFragment = { __typename?: 'User', id: string, name: string, email: string };

export type UserBasicFragment = { __typename?: 'User', id: string, name: string, email: string, created_at: any, deleted_at?: Maybe<any>, roles: Array<{ __typename?: 'Role', name: string }> };

export type UserExtendedFragment = { __typename?: 'User', permissionsList: Array<string>, id: string, name: string, email: string, created_at: any, deleted_at?: Maybe<any>, roles: Array<{ __typename?: 'Role', name: string }> };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', permissionsList: Array<string>, id: string, name: string, email: string, created_at: any, deleted_at?: Maybe<any>, roles: Array<{ __typename?: 'Role', name: string }> } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type RestoreUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreUserMutation = { __typename?: 'Mutation', restoreUser: { __typename?: 'User', id: string } };

export type UpdatePasswordMutationVariables = Exact<{
  updatePasswordInput: UpdatePassword;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UpdatePasswordResponse', status: string, message?: Maybe<string> } };

export type UpdateProfileMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> } };

export type UsersQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: Maybe<{ __typename?: 'UserPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, hasMorePages: boolean, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'User', id: string, name: string, email: string, created_at: any, deleted_at?: Maybe<any>, roles: Array<{ __typename?: 'Role', name: string }> }> }> };

export type UserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  trashed?: Maybe<Trashed>;
}>;


export type UserQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', permissionsList: Array<string>, id: string, name: string, email: string, created_at: any, deleted_at?: Maybe<any>, roles: Array<{ __typename?: 'Role', name: string }> }> };

export type ProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ProfileQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, name: string, email: string }> };

export type AuthenticatedUserFragment = { __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> };

export type RegisterMutationVariables = Exact<{
  registerInput?: Maybe<RegisterInput>;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'RegisterResponse', status: RegisterStatuses } };

export type LoginMutationVariables = Exact<{
  loginInput?: Maybe<LoginInput>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', access_token?: Maybe<string>, refresh_token?: Maybe<string>, expires_in?: Maybe<number>, token_type?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> }> } };

export type LdapLoginMutationVariables = Exact<{
  ldapLoginInput?: Maybe<LdapLoginInput>;
}>;


export type LdapLoginMutation = { __typename?: 'Mutation', ldapLogin: { __typename?: 'AuthPayload', access_token?: Maybe<string>, refresh_token?: Maybe<string>, expires_in?: Maybe<number>, token_type?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> }> } };

export type RefreshTokenMutationVariables = Exact<{
  refreshTokenInput?: Maybe<RefreshTokenInput>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'RefreshTokenPayload', access_token: string, refresh_token: string, expires_in: number, token_type: string } };

export type SocialLoginMutationVariables = Exact<{
  socialLoginInput: SocialLoginInput;
}>;


export type SocialLoginMutation = { __typename?: 'Mutation', socialLogin: { __typename?: 'AuthPayload', access_token?: Maybe<string>, refresh_token?: Maybe<string>, expires_in?: Maybe<number>, token_type?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, name: string, email: string, permissionsList: Array<string> }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResponse', status: string, message?: Maybe<string> } };

export const PaginatorInfoFragmentDoc = gql`
    fragment PaginatorInfo on PaginatorInfo {
  count
  currentPage
  hasMorePages
  lastPage
  perPage
  total
}
    `;
export const DeviceTypeBasicFragmentDoc = gql`
    fragment DeviceTypeBasic on DeviceType {
  id
  name
}
    `;
export const SoftwareBasicFragmentDoc = gql`
    fragment SoftwareBasic on Software {
  id
  name
}
    `;
export const DeviceBasicFragmentDoc = gql`
    fragment DeviceBasic on Device {
  id
  name
  deleted_at
  deviceType {
    ...DeviceTypeBasic
  }
  software {
    ...SoftwareBasic
  }
}
    ${DeviceTypeBasicFragmentDoc}
${SoftwareBasicFragmentDoc}`;
export const DeviceWithServerFragmentDoc = gql`
    fragment DeviceWithServer on Device {
  ...DeviceBasic
  server {
    id
    name
  }
}
    ${DeviceBasicFragmentDoc}`;
export const ReservationsCurrentFragmentDoc = gql`
    fragment ReservationsCurrent on Reservation {
  start
  end
  device {
    ...DeviceWithServer
  }
}
    ${DeviceWithServerFragmentDoc}`;
export const ExperimentBasicFragmentDoc = gql`
    fragment ExperimentBasic on Experiment {
  id
  name
  software {
    ...SoftwareBasic
  }
  device {
    id
    name
  }
  deviceType {
    id
    name
  }
  server {
    id
    api_domain
  }
  experiment_commands {
    name
    arguments {
      name
      label
      row
      order
      default_value
      options {
        name
        value
      }
    }
  }
  has_schema
  commands
}
    ${SoftwareBasicFragmentDoc}`;
export const ArgumentBasicFragmentDoc = gql`
    fragment ArgumentBasic on Argument {
  name
  label
  default_value
  row
  order
  options {
    name
    value
    output_value
  }
}
    `;
export const ExperimentSchemaFragmentDoc = gql`
    fragment ExperimentSchema on Schema {
  id
  name
  note
  schema
  preview
  deviceType {
    id
    name
  }
  software {
    id
    name
  }
  arguments {
    ...ArgumentBasic
  }
}
    ${ArgumentBasicFragmentDoc}`;
export const UserExperimentDashboardFragmentDoc = gql`
    fragment UserExperimentDashboard on UserExperiment {
  id
  experiment {
    id
    device {
      name
      deviceType {
        id
        name
      }
    }
    server {
      ip_address
      api_domain
      websocket_port
    }
    software {
      id
      name
    }
  }
  schema {
    ...ExperimentSchema
  }
  sampling_rate
  simulation_time
  filled
  created_at
  updated_at
}
    ${ExperimentSchemaFragmentDoc}`;
export const CameraStatusFragmentDoc = gql`
    fragment CameraStatus on CameraStatus {
  isConnected
  status
}
    `;
export const VideoStreamStatusFragmentDoc = gql`
    fragment VideoStreamStatus on VideoStreamStatus {
  isRunning
  status
}
    `;
export const StopVideoStreamStatusFragmentDoc = gql`
    fragment StopVideoStreamStatus on StopVideoStreamStatus {
  isStopped
  status
}
    `;
export const ReservationBasicFragmentDoc = gql`
    fragment ReservationBasic on Reservation {
  id
  title
  start
  end
  user {
    id
  }
}
    `;
export const DeviceWithReservationsFragmentDoc = gql`
    fragment DeviceWithReservations on Device {
  ...DeviceBasic
  reservations(start: $reservationStart) {
    ...ReservationBasic
  }
}
    ${DeviceBasicFragmentDoc}
${ReservationBasicFragmentDoc}`;
export const RoleBasicFragmentDoc = gql`
    fragment RoleBasic on Role {
  id
  name
}
    `;
export const PermissionBasicFragmentDoc = gql`
    fragment PermissionBasic on Permission {
  id
  name
}
    `;
export const RoleExtendedFragmentDoc = gql`
    fragment RoleExtended on Role {
  ...RoleBasic
  permissions {
    ...PermissionBasic
  }
}
    ${RoleBasicFragmentDoc}
${PermissionBasicFragmentDoc}`;
export const SchemaBasicFragmentDoc = gql`
    fragment SchemaBasic on Schema {
  id
  name
  schema
  preview
  deviceType {
    name
  }
  software {
    name
  }
  deleted_at
}
    `;
export const SchemaExtendedFragmentDoc = gql`
    fragment SchemaExtended on Schema {
  id
  name
  type
  availableTypes
  note
  schema
  preview
  deviceType {
    id
    name
  }
  software {
    id
    name
  }
  arguments {
    ...ArgumentBasic
  }
}
    ${ArgumentBasicFragmentDoc}`;
export const ServerBasicFragmentDoc = gql`
    fragment ServerBasic on Server {
  id
  name
  ip_address
  api_domain
  available
  production
  enabled
  deleted_at
  devices(trashed: $trashedDevices) {
    ...DeviceBasic
  }
}
    ${DeviceBasicFragmentDoc}`;
export const ServerExtendedFragmentDoc = gql`
    fragment ServerExtended on Server {
  ...ServerBasic
  websocket_port
}
    ${ServerBasicFragmentDoc}`;
export const UserExperimentBasicFragmentDoc = gql`
    fragment UserExperimentBasic on UserExperiment {
  id
  filled
  simulation_time
  result
  user {
    id
    name
  }
  device(trashed: WITH) {
    name
  }
  experiment(trashed: WITH) {
    deviceType {
      name
    }
    software {
      name
    }
    output_arguments {
      name
      title
      defaultVisibilityFor
    }
  }
  evaluation {
    name
    value
  }
  created_at
  deleted_at
}
    `;
export const UserExperimentSchemaFragmentDoc = gql`
    fragment UserExperimentSchema on Schema {
  name
  preview
}
    `;
export const UserExperimentExtendedFragmentDoc = gql`
    fragment UserExperimentExtended on UserExperiment {
  ...UserExperimentBasic
  note
  remote_id
  schema(trashed: WITH) {
    ...UserExperimentSchema
  }
  input {
    script_name
    input {
      name
      value
      formatted_value
      label
    }
  }
  output {
    name
    data
  }
}
    ${UserExperimentBasicFragmentDoc}
${UserExperimentSchemaFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on User {
  id
  name
  email
}
    `;
export const UserBasicFragmentDoc = gql`
    fragment UserBasic on User {
  id
  name
  email
  created_at
  deleted_at
  roles {
    name
  }
}
    `;
export const UserExtendedFragmentDoc = gql`
    fragment UserExtended on User {
  ...UserBasic
  permissionsList
}
    ${UserBasicFragmentDoc}`;
export const AuthenticatedUserFragmentDoc = gql`
    fragment AuthenticatedUser on User {
  id
  name
  email
  permissionsList
}
    `;
export const RunUserExperimentDocument = gql`
    mutation RunUserExperiment($runUserExperimentInput: RunUserExperimentInput!) {
  runUserExperiment(input: $runUserExperimentInput) {
    ...UserExperimentDashboard
  }
}
    ${UserExperimentDashboardFragmentDoc}`;
export type RunUserExperimentMutationFn = Apollo.MutationFunction<RunUserExperimentMutation, RunUserExperimentMutationVariables>;

/**
 * __useRunUserExperimentMutation__
 *
 * To run a mutation, you first call `useRunUserExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunUserExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runUserExperimentMutation, { data, loading, error }] = useRunUserExperimentMutation({
 *   variables: {
 *      runUserExperimentInput: // value for 'runUserExperimentInput'
 *   },
 * });
 */
export function useRunUserExperimentMutation(baseOptions?: Apollo.MutationHookOptions<RunUserExperimentMutation, RunUserExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunUserExperimentMutation, RunUserExperimentMutationVariables>(RunUserExperimentDocument, options);
      }
export type RunUserExperimentMutationHookResult = ReturnType<typeof useRunUserExperimentMutation>;
export type RunUserExperimentMutationResult = Apollo.MutationResult<RunUserExperimentMutation>;
export type RunUserExperimentMutationOptions = Apollo.BaseMutationOptions<RunUserExperimentMutation, RunUserExperimentMutationVariables>;
export const QueueUserExperimentDocument = gql`
    mutation QueueUserExperiment($queueUserExperimentInput: QueueUserExperimentInput!) {
  queueUserExperiment(input: $queueUserExperimentInput) {
    ...UserExperimentDashboard
  }
}
    ${UserExperimentDashboardFragmentDoc}`;
export type QueueUserExperimentMutationFn = Apollo.MutationFunction<QueueUserExperimentMutation, QueueUserExperimentMutationVariables>;

/**
 * __useQueueUserExperimentMutation__
 *
 * To run a mutation, you first call `useQueueUserExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueUserExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueUserExperimentMutation, { data, loading, error }] = useQueueUserExperimentMutation({
 *   variables: {
 *      queueUserExperimentInput: // value for 'queueUserExperimentInput'
 *   },
 * });
 */
export function useQueueUserExperimentMutation(baseOptions?: Apollo.MutationHookOptions<QueueUserExperimentMutation, QueueUserExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QueueUserExperimentMutation, QueueUserExperimentMutationVariables>(QueueUserExperimentDocument, options);
      }
export type QueueUserExperimentMutationHookResult = ReturnType<typeof useQueueUserExperimentMutation>;
export type QueueUserExperimentMutationResult = Apollo.MutationResult<QueueUserExperimentMutation>;
export type QueueUserExperimentMutationOptions = Apollo.BaseMutationOptions<QueueUserExperimentMutation, QueueUserExperimentMutationVariables>;
export const StartVideoStreamDocument = gql`
    mutation StartVideoStream($deviceId: ID!) {
  startVideoStream(device_id: $deviceId) {
    ...VideoStreamStatus
  }
}
    ${VideoStreamStatusFragmentDoc}`;
export type StartVideoStreamMutationFn = Apollo.MutationFunction<StartVideoStreamMutation, StartVideoStreamMutationVariables>;

/**
 * __useStartVideoStreamMutation__
 *
 * To run a mutation, you first call `useStartVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVideoStreamMutation, { data, loading, error }] = useStartVideoStreamMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useStartVideoStreamMutation(baseOptions?: Apollo.MutationHookOptions<StartVideoStreamMutation, StartVideoStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartVideoStreamMutation, StartVideoStreamMutationVariables>(StartVideoStreamDocument, options);
      }
export type StartVideoStreamMutationHookResult = ReturnType<typeof useStartVideoStreamMutation>;
export type StartVideoStreamMutationResult = Apollo.MutationResult<StartVideoStreamMutation>;
export type StartVideoStreamMutationOptions = Apollo.BaseMutationOptions<StartVideoStreamMutation, StartVideoStreamMutationVariables>;
export const StopVideoStreamDocument = gql`
    mutation StopVideoStream($deviceId: ID!) {
  stopVideoStream(device_id: $deviceId) {
    ...StopVideoStreamStatus
  }
}
    ${StopVideoStreamStatusFragmentDoc}`;
export type StopVideoStreamMutationFn = Apollo.MutationFunction<StopVideoStreamMutation, StopVideoStreamMutationVariables>;

/**
 * __useStopVideoStreamMutation__
 *
 * To run a mutation, you first call `useStopVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopVideoStreamMutation, { data, loading, error }] = useStopVideoStreamMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useStopVideoStreamMutation(baseOptions?: Apollo.MutationHookOptions<StopVideoStreamMutation, StopVideoStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopVideoStreamMutation, StopVideoStreamMutationVariables>(StopVideoStreamDocument, options);
      }
export type StopVideoStreamMutationHookResult = ReturnType<typeof useStopVideoStreamMutation>;
export type StopVideoStreamMutationResult = Apollo.MutationResult<StopVideoStreamMutation>;
export type StopVideoStreamMutationOptions = Apollo.BaseMutationOptions<StopVideoStreamMutation, StopVideoStreamMutationVariables>;
export const ReservationsCurrentDocument = gql`
    query ReservationsCurrent {
  reservationsCurrent {
    ...ReservationsCurrent
  }
}
    ${ReservationsCurrentFragmentDoc}`;

/**
 * __useReservationsCurrentQuery__
 *
 * To run a query within a React component, call `useReservationsCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useReservationsCurrentQuery(baseOptions?: Apollo.QueryHookOptions<ReservationsCurrentQuery, ReservationsCurrentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsCurrentQuery, ReservationsCurrentQueryVariables>(ReservationsCurrentDocument, options);
      }
export function useReservationsCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsCurrentQuery, ReservationsCurrentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsCurrentQuery, ReservationsCurrentQueryVariables>(ReservationsCurrentDocument, options);
        }
export type ReservationsCurrentQueryHookResult = ReturnType<typeof useReservationsCurrentQuery>;
export type ReservationsCurrentLazyQueryHookResult = ReturnType<typeof useReservationsCurrentLazyQuery>;
export type ReservationsCurrentQueryResult = Apollo.QueryResult<ReservationsCurrentQuery, ReservationsCurrentQueryVariables>;
export const ExperimentsDocument = gql`
    query Experiments($serverId: ID, $deviceId: ID) {
  experiments(server_id: $serverId, device_id: $deviceId) {
    ...ExperimentBasic
  }
}
    ${ExperimentBasicFragmentDoc}`;

/**
 * __useExperimentsQuery__
 *
 * To run a query within a React component, call `useExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperimentsQuery({
 *   variables: {
 *      serverId: // value for 'serverId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useExperimentsQuery(baseOptions?: Apollo.QueryHookOptions<ExperimentsQuery, ExperimentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExperimentsQuery, ExperimentsQueryVariables>(ExperimentsDocument, options);
      }
export function useExperimentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperimentsQuery, ExperimentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExperimentsQuery, ExperimentsQueryVariables>(ExperimentsDocument, options);
        }
export type ExperimentsQueryHookResult = ReturnType<typeof useExperimentsQuery>;
export type ExperimentsLazyQueryHookResult = ReturnType<typeof useExperimentsLazyQuery>;
export type ExperimentsQueryResult = Apollo.QueryResult<ExperimentsQuery, ExperimentsQueryVariables>;
export const ExperimentSchemasDocument = gql`
    query ExperimentSchemas($deviceTypeId: ID!, $softwareId: ID!) {
  schemas(
    device_type_id: $deviceTypeId
    software_id: $softwareId
    trashed: WITHOUT
  ) {
    ...ExperimentSchema
  }
}
    ${ExperimentSchemaFragmentDoc}`;

/**
 * __useExperimentSchemasQuery__
 *
 * To run a query within a React component, call `useExperimentSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperimentSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperimentSchemasQuery({
 *   variables: {
 *      deviceTypeId: // value for 'deviceTypeId'
 *      softwareId: // value for 'softwareId'
 *   },
 * });
 */
export function useExperimentSchemasQuery(baseOptions: Apollo.QueryHookOptions<ExperimentSchemasQuery, ExperimentSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExperimentSchemasQuery, ExperimentSchemasQueryVariables>(ExperimentSchemasDocument, options);
      }
export function useExperimentSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperimentSchemasQuery, ExperimentSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExperimentSchemasQuery, ExperimentSchemasQueryVariables>(ExperimentSchemasDocument, options);
        }
export type ExperimentSchemasQueryHookResult = ReturnType<typeof useExperimentSchemasQuery>;
export type ExperimentSchemasLazyQueryHookResult = ReturnType<typeof useExperimentSchemasLazyQuery>;
export type ExperimentSchemasQueryResult = Apollo.QueryResult<ExperimentSchemasQuery, ExperimentSchemasQueryVariables>;
export const UserExperimentCurrentDocument = gql`
    query UserExperimentCurrent {
  userExperimentCurrent {
    ...UserExperimentDashboard
  }
}
    ${UserExperimentDashboardFragmentDoc}`;

/**
 * __useUserExperimentCurrentQuery__
 *
 * To run a query within a React component, call `useUserExperimentCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExperimentCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExperimentCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserExperimentCurrentQuery(baseOptions?: Apollo.QueryHookOptions<UserExperimentCurrentQuery, UserExperimentCurrentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserExperimentCurrentQuery, UserExperimentCurrentQueryVariables>(UserExperimentCurrentDocument, options);
      }
export function useUserExperimentCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExperimentCurrentQuery, UserExperimentCurrentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserExperimentCurrentQuery, UserExperimentCurrentQueryVariables>(UserExperimentCurrentDocument, options);
        }
export type UserExperimentCurrentQueryHookResult = ReturnType<typeof useUserExperimentCurrentQuery>;
export type UserExperimentCurrentLazyQueryHookResult = ReturnType<typeof useUserExperimentCurrentLazyQuery>;
export type UserExperimentCurrentQueryResult = Apollo.QueryResult<UserExperimentCurrentQuery, UserExperimentCurrentQueryVariables>;
export const CameraStatusDocument = gql`
    query CameraStatus($deviceId: ID!) {
  cameraStatus(device_id: $deviceId) {
    ...CameraStatus
  }
}
    ${CameraStatusFragmentDoc}`;

/**
 * __useCameraStatusQuery__
 *
 * To run a query within a React component, call `useCameraStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCameraStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraStatusQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useCameraStatusQuery(baseOptions: Apollo.QueryHookOptions<CameraStatusQuery, CameraStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CameraStatusQuery, CameraStatusQueryVariables>(CameraStatusDocument, options);
      }
export function useCameraStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CameraStatusQuery, CameraStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CameraStatusQuery, CameraStatusQueryVariables>(CameraStatusDocument, options);
        }
export type CameraStatusQueryHookResult = ReturnType<typeof useCameraStatusQuery>;
export type CameraStatusLazyQueryHookResult = ReturnType<typeof useCameraStatusLazyQuery>;
export type CameraStatusQueryResult = Apollo.QueryResult<CameraStatusQuery, CameraStatusQueryVariables>;
export const VideoStreamStatusDocument = gql`
    query VideoStreamStatus($deviceId: ID!) {
  videoStreamStatus(device_id: $deviceId) {
    ...VideoStreamStatus
  }
}
    ${VideoStreamStatusFragmentDoc}`;

/**
 * __useVideoStreamStatusQuery__
 *
 * To run a query within a React component, call `useVideoStreamStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoStreamStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoStreamStatusQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useVideoStreamStatusQuery(baseOptions: Apollo.QueryHookOptions<VideoStreamStatusQuery, VideoStreamStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoStreamStatusQuery, VideoStreamStatusQueryVariables>(VideoStreamStatusDocument, options);
      }
export function useVideoStreamStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoStreamStatusQuery, VideoStreamStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoStreamStatusQuery, VideoStreamStatusQueryVariables>(VideoStreamStatusDocument, options);
        }
export type VideoStreamStatusQueryHookResult = ReturnType<typeof useVideoStreamStatusQuery>;
export type VideoStreamStatusLazyQueryHookResult = ReturnType<typeof useVideoStreamStatusLazyQuery>;
export type VideoStreamStatusQueryResult = Apollo.QueryResult<VideoStreamStatusQuery, VideoStreamStatusQueryVariables>;
export const CreateReservationDocument = gql`
    mutation CreateReservation($createReservationInput: CreateReservationInput!) {
  createReservation(input: $createReservationInput) {
    id
  }
}
    `;
export type CreateReservationMutationFn = Apollo.MutationFunction<CreateReservationMutation, CreateReservationMutationVariables>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      createReservationInput: // value for 'createReservationInput'
 *   },
 * });
 */
export function useCreateReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(CreateReservationDocument, options);
      }
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<CreateReservationMutation, CreateReservationMutationVariables>;
export const UpdateReservationDocument = gql`
    mutation UpdateReservation($updateReservationInput: UpdateReservationInput!) {
  updateReservation(input: $updateReservationInput) {
    id
  }
}
    `;
export type UpdateReservationMutationFn = Apollo.MutationFunction<UpdateReservationMutation, UpdateReservationMutationVariables>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      updateReservationInput: // value for 'updateReservationInput'
 *   },
 * });
 */
export function useUpdateReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationMutation, UpdateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument, options);
      }
export type UpdateReservationMutationHookResult = ReturnType<typeof useUpdateReservationMutation>;
export type UpdateReservationMutationResult = Apollo.MutationResult<UpdateReservationMutation>;
export type UpdateReservationMutationOptions = Apollo.BaseMutationOptions<UpdateReservationMutation, UpdateReservationMutationVariables>;
export const DeleteReservationDocument = gql`
    mutation DeleteReservation($id: ID!) {
  deleteReservation(id: $id) {
    id
  }
}
    `;
export type DeleteReservationMutationFn = Apollo.MutationFunction<DeleteReservationMutation, DeleteReservationMutationVariables>;

/**
 * __useDeleteReservationMutation__
 *
 * To run a mutation, you first call `useDeleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationMutation, { data, loading, error }] = useDeleteReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReservationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReservationMutation, DeleteReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReservationMutation, DeleteReservationMutationVariables>(DeleteReservationDocument, options);
      }
export type DeleteReservationMutationHookResult = ReturnType<typeof useDeleteReservationMutation>;
export type DeleteReservationMutationResult = Apollo.MutationResult<DeleteReservationMutation>;
export type DeleteReservationMutationOptions = Apollo.BaseMutationOptions<DeleteReservationMutation, DeleteReservationMutationVariables>;
export const DevicesDocument = gql`
    query Devices($reservationStart: DateTimeRange) {
  devices {
    ...DeviceWithReservations
  }
}
    ${DeviceWithReservationsFragmentDoc}`;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      reservationStart: // value for 'reservationStart'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const ServersWithDevicesDocument = gql`
    query ServersWithDevices($production: Boolean, $enabled: Boolean = true, $reservationStart: DateTimeRange) {
  servers(production: $production, enabled: $enabled) {
    production
    devices {
      ...DeviceWithReservations
    }
  }
}
    ${DeviceWithReservationsFragmentDoc}`;

/**
 * __useServersWithDevicesQuery__
 *
 * To run a query within a React component, call `useServersWithDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServersWithDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServersWithDevicesQuery({
 *   variables: {
 *      production: // value for 'production'
 *      enabled: // value for 'enabled'
 *      reservationStart: // value for 'reservationStart'
 *   },
 * });
 */
export function useServersWithDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ServersWithDevicesQuery, ServersWithDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServersWithDevicesQuery, ServersWithDevicesQueryVariables>(ServersWithDevicesDocument, options);
      }
export function useServersWithDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServersWithDevicesQuery, ServersWithDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServersWithDevicesQuery, ServersWithDevicesQueryVariables>(ServersWithDevicesDocument, options);
        }
export type ServersWithDevicesQueryHookResult = ReturnType<typeof useServersWithDevicesQuery>;
export type ServersWithDevicesLazyQueryHookResult = ReturnType<typeof useServersWithDevicesLazyQuery>;
export type ServersWithDevicesQueryResult = Apollo.QueryResult<ServersWithDevicesQuery, ServersWithDevicesQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($createRoleInput: CreateRoleInput!) {
  createRole(input: $createRoleInput) {
    ...RoleExtended
  }
}
    ${RoleExtendedFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      createRoleInput: // value for 'createRoleInput'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($updateRoleInput: UpdateRoleInput!) {
  updateRole(input: $updateRoleInput) {
    ...RoleExtended
  }
}
    ${RoleExtendedFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      updateRoleInput: // value for 'updateRoleInput'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id) {
    id
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    ...RoleBasic
  }
}
    ${RoleBasicFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const RoleDocument = gql`
    query Role($id: ID!) {
  role(id: $id) {
    ...RoleExtended
  }
}
    ${RoleExtendedFragmentDoc}`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  permissions {
    ...PermissionBasic
  }
}
    ${PermissionBasicFragmentDoc}`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const CreateSchemaDocument = gql`
    mutation CreateSchema($createSchemaInput: CreateSchemaInput!) {
  createSchema(input: $createSchemaInput) {
    ...SchemaExtended
  }
}
    ${SchemaExtendedFragmentDoc}`;
export type CreateSchemaMutationFn = Apollo.MutationFunction<CreateSchemaMutation, CreateSchemaMutationVariables>;

/**
 * __useCreateSchemaMutation__
 *
 * To run a mutation, you first call `useCreateSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchemaMutation, { data, loading, error }] = useCreateSchemaMutation({
 *   variables: {
 *      createSchemaInput: // value for 'createSchemaInput'
 *   },
 * });
 */
export function useCreateSchemaMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchemaMutation, CreateSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchemaMutation, CreateSchemaMutationVariables>(CreateSchemaDocument, options);
      }
export type CreateSchemaMutationHookResult = ReturnType<typeof useCreateSchemaMutation>;
export type CreateSchemaMutationResult = Apollo.MutationResult<CreateSchemaMutation>;
export type CreateSchemaMutationOptions = Apollo.BaseMutationOptions<CreateSchemaMutation, CreateSchemaMutationVariables>;
export const UpdateSchemaDocument = gql`
    mutation UpdateSchema($updateSchemaInput: UpdateSchemaInput!) {
  updateSchema(input: $updateSchemaInput) {
    ...SchemaExtended
  }
}
    ${SchemaExtendedFragmentDoc}`;
export type UpdateSchemaMutationFn = Apollo.MutationFunction<UpdateSchemaMutation, UpdateSchemaMutationVariables>;

/**
 * __useUpdateSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemaMutation, { data, loading, error }] = useUpdateSchemaMutation({
 *   variables: {
 *      updateSchemaInput: // value for 'updateSchemaInput'
 *   },
 * });
 */
export function useUpdateSchemaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemaMutation, UpdateSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemaMutation, UpdateSchemaMutationVariables>(UpdateSchemaDocument, options);
      }
export type UpdateSchemaMutationHookResult = ReturnType<typeof useUpdateSchemaMutation>;
export type UpdateSchemaMutationResult = Apollo.MutationResult<UpdateSchemaMutation>;
export type UpdateSchemaMutationOptions = Apollo.BaseMutationOptions<UpdateSchemaMutation, UpdateSchemaMutationVariables>;
export const DeleteSchemaDocument = gql`
    mutation DeleteSchema($id: ID!) {
  deleteSchema(id: $id) {
    id
  }
}
    `;
export type DeleteSchemaMutationFn = Apollo.MutationFunction<DeleteSchemaMutation, DeleteSchemaMutationVariables>;

/**
 * __useDeleteSchemaMutation__
 *
 * To run a mutation, you first call `useDeleteSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemaMutation, { data, loading, error }] = useDeleteSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemaMutation, DeleteSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemaMutation, DeleteSchemaMutationVariables>(DeleteSchemaDocument, options);
      }
export type DeleteSchemaMutationHookResult = ReturnType<typeof useDeleteSchemaMutation>;
export type DeleteSchemaMutationResult = Apollo.MutationResult<DeleteSchemaMutation>;
export type DeleteSchemaMutationOptions = Apollo.BaseMutationOptions<DeleteSchemaMutation, DeleteSchemaMutationVariables>;
export const RestoreSchemaDocument = gql`
    mutation RestoreSchema($id: ID!) {
  restoreSchema(id: $id) {
    id
  }
}
    `;
export type RestoreSchemaMutationFn = Apollo.MutationFunction<RestoreSchemaMutation, RestoreSchemaMutationVariables>;

/**
 * __useRestoreSchemaMutation__
 *
 * To run a mutation, you first call `useRestoreSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSchemaMutation, { data, loading, error }] = useRestoreSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreSchemaMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSchemaMutation, RestoreSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreSchemaMutation, RestoreSchemaMutationVariables>(RestoreSchemaDocument, options);
      }
export type RestoreSchemaMutationHookResult = ReturnType<typeof useRestoreSchemaMutation>;
export type RestoreSchemaMutationResult = Apollo.MutationResult<RestoreSchemaMutation>;
export type RestoreSchemaMutationOptions = Apollo.BaseMutationOptions<RestoreSchemaMutation, RestoreSchemaMutationVariables>;
export const SchemasDocument = gql`
    query Schemas($trashed: Trashed) {
  schemas(trashed: $trashed) {
    ...SchemaBasic
  }
}
    ${SchemaBasicFragmentDoc}`;

/**
 * __useSchemasQuery__
 *
 * To run a query within a React component, call `useSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemasQuery({
 *   variables: {
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useSchemasQuery(baseOptions?: Apollo.QueryHookOptions<SchemasQuery, SchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemasQuery, SchemasQueryVariables>(SchemasDocument, options);
      }
export function useSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemasQuery, SchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemasQuery, SchemasQueryVariables>(SchemasDocument, options);
        }
export type SchemasQueryHookResult = ReturnType<typeof useSchemasQuery>;
export type SchemasLazyQueryHookResult = ReturnType<typeof useSchemasLazyQuery>;
export type SchemasQueryResult = Apollo.QueryResult<SchemasQuery, SchemasQueryVariables>;
export const SchemaDocument = gql`
    query Schema($id: ID!) {
  schema(id: $id) {
    ...SchemaExtended
  }
}
    ${SchemaExtendedFragmentDoc}`;

/**
 * __useSchemaQuery__
 *
 * To run a query within a React component, call `useSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchemaQuery(baseOptions: Apollo.QueryHookOptions<SchemaQuery, SchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemaQuery, SchemaQueryVariables>(SchemaDocument, options);
      }
export function useSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemaQuery, SchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemaQuery, SchemaQueryVariables>(SchemaDocument, options);
        }
export type SchemaQueryHookResult = ReturnType<typeof useSchemaQuery>;
export type SchemaLazyQueryHookResult = ReturnType<typeof useSchemaLazyQuery>;
export type SchemaQueryResult = Apollo.QueryResult<SchemaQuery, SchemaQueryVariables>;
export const AvailableSchemaTypesDocument = gql`
    query AvailableSchemaTypes {
  availableSchemaTypes
}
    `;

/**
 * __useAvailableSchemaTypesQuery__
 *
 * To run a query within a React component, call `useAvailableSchemaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSchemaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSchemaTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableSchemaTypesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableSchemaTypesQuery, AvailableSchemaTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableSchemaTypesQuery, AvailableSchemaTypesQueryVariables>(AvailableSchemaTypesDocument, options);
      }
export function useAvailableSchemaTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableSchemaTypesQuery, AvailableSchemaTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableSchemaTypesQuery, AvailableSchemaTypesQueryVariables>(AvailableSchemaTypesDocument, options);
        }
export type AvailableSchemaTypesQueryHookResult = ReturnType<typeof useAvailableSchemaTypesQuery>;
export type AvailableSchemaTypesLazyQueryHookResult = ReturnType<typeof useAvailableSchemaTypesLazyQuery>;
export type AvailableSchemaTypesQueryResult = Apollo.QueryResult<AvailableSchemaTypesQuery, AvailableSchemaTypesQueryVariables>;
export const DeviceTypesAndSoftwareDocument = gql`
    query DeviceTypesAndSoftware {
  deviceTypes {
    id
    name
    experiment {
      output_arguments {
        name
      }
    }
  }
  software {
    id
    name
  }
}
    `;

/**
 * __useDeviceTypesAndSoftwareQuery__
 *
 * To run a query within a React component, call `useDeviceTypesAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTypesAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTypesAndSoftwareQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceTypesAndSoftwareQuery(baseOptions?: Apollo.QueryHookOptions<DeviceTypesAndSoftwareQuery, DeviceTypesAndSoftwareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTypesAndSoftwareQuery, DeviceTypesAndSoftwareQueryVariables>(DeviceTypesAndSoftwareDocument, options);
      }
export function useDeviceTypesAndSoftwareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTypesAndSoftwareQuery, DeviceTypesAndSoftwareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTypesAndSoftwareQuery, DeviceTypesAndSoftwareQueryVariables>(DeviceTypesAndSoftwareDocument, options);
        }
export type DeviceTypesAndSoftwareQueryHookResult = ReturnType<typeof useDeviceTypesAndSoftwareQuery>;
export type DeviceTypesAndSoftwareLazyQueryHookResult = ReturnType<typeof useDeviceTypesAndSoftwareLazyQuery>;
export type DeviceTypesAndSoftwareQueryResult = Apollo.QueryResult<DeviceTypesAndSoftwareQuery, DeviceTypesAndSoftwareQueryVariables>;
export const CreateServerDocument = gql`
    mutation CreateServer($createServerInput: CreateServerInput!, $trashedDevices: Trashed = WITHOUT) {
  createServer(input: $createServerInput) {
    ...ServerExtended
  }
}
    ${ServerExtendedFragmentDoc}`;
export type CreateServerMutationFn = Apollo.MutationFunction<CreateServerMutation, CreateServerMutationVariables>;

/**
 * __useCreateServerMutation__
 *
 * To run a mutation, you first call `useCreateServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServerMutation, { data, loading, error }] = useCreateServerMutation({
 *   variables: {
 *      createServerInput: // value for 'createServerInput'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useCreateServerMutation(baseOptions?: Apollo.MutationHookOptions<CreateServerMutation, CreateServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServerMutation, CreateServerMutationVariables>(CreateServerDocument, options);
      }
export type CreateServerMutationHookResult = ReturnType<typeof useCreateServerMutation>;
export type CreateServerMutationResult = Apollo.MutationResult<CreateServerMutation>;
export type CreateServerMutationOptions = Apollo.BaseMutationOptions<CreateServerMutation, CreateServerMutationVariables>;
export const UpdateServerDocument = gql`
    mutation UpdateServer($updateServerInput: UpdateServerInput!, $trashedDevices: Trashed = WITHOUT) {
  updateServer(input: $updateServerInput) {
    ...ServerExtended
  }
}
    ${ServerExtendedFragmentDoc}`;
export type UpdateServerMutationFn = Apollo.MutationFunction<UpdateServerMutation, UpdateServerMutationVariables>;

/**
 * __useUpdateServerMutation__
 *
 * To run a mutation, you first call `useUpdateServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServerMutation, { data, loading, error }] = useUpdateServerMutation({
 *   variables: {
 *      updateServerInput: // value for 'updateServerInput'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useUpdateServerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServerMutation, UpdateServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServerMutation, UpdateServerMutationVariables>(UpdateServerDocument, options);
      }
export type UpdateServerMutationHookResult = ReturnType<typeof useUpdateServerMutation>;
export type UpdateServerMutationResult = Apollo.MutationResult<UpdateServerMutation>;
export type UpdateServerMutationOptions = Apollo.BaseMutationOptions<UpdateServerMutation, UpdateServerMutationVariables>;
export const DeleteServerDocument = gql`
    mutation DeleteServer($id: ID!) {
  deleteServer(id: $id) {
    id
  }
}
    `;
export type DeleteServerMutationFn = Apollo.MutationFunction<DeleteServerMutation, DeleteServerMutationVariables>;

/**
 * __useDeleteServerMutation__
 *
 * To run a mutation, you first call `useDeleteServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServerMutation, { data, loading, error }] = useDeleteServerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServerMutation, DeleteServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServerMutation, DeleteServerMutationVariables>(DeleteServerDocument, options);
      }
export type DeleteServerMutationHookResult = ReturnType<typeof useDeleteServerMutation>;
export type DeleteServerMutationResult = Apollo.MutationResult<DeleteServerMutation>;
export type DeleteServerMutationOptions = Apollo.BaseMutationOptions<DeleteServerMutation, DeleteServerMutationVariables>;
export const RestoreServerDocument = gql`
    mutation RestoreServer($id: ID!) {
  restoreServer(id: $id) {
    id
  }
}
    `;
export type RestoreServerMutationFn = Apollo.MutationFunction<RestoreServerMutation, RestoreServerMutationVariables>;

/**
 * __useRestoreServerMutation__
 *
 * To run a mutation, you first call `useRestoreServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreServerMutation, { data, loading, error }] = useRestoreServerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreServerMutation(baseOptions?: Apollo.MutationHookOptions<RestoreServerMutation, RestoreServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreServerMutation, RestoreServerMutationVariables>(RestoreServerDocument, options);
      }
export type RestoreServerMutationHookResult = ReturnType<typeof useRestoreServerMutation>;
export type RestoreServerMutationResult = Apollo.MutationResult<RestoreServerMutation>;
export type RestoreServerMutationOptions = Apollo.BaseMutationOptions<RestoreServerMutation, RestoreServerMutationVariables>;
export const RestoreServerExtendedDocument = gql`
    mutation RestoreServerExtended($id: ID!, $trashedDevices: Trashed) {
  restoreServer(id: $id) {
    ...ServerExtended
  }
}
    ${ServerExtendedFragmentDoc}`;
export type RestoreServerExtendedMutationFn = Apollo.MutationFunction<RestoreServerExtendedMutation, RestoreServerExtendedMutationVariables>;

/**
 * __useRestoreServerExtendedMutation__
 *
 * To run a mutation, you first call `useRestoreServerExtendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreServerExtendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreServerExtendedMutation, { data, loading, error }] = useRestoreServerExtendedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useRestoreServerExtendedMutation(baseOptions?: Apollo.MutationHookOptions<RestoreServerExtendedMutation, RestoreServerExtendedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreServerExtendedMutation, RestoreServerExtendedMutationVariables>(RestoreServerExtendedDocument, options);
      }
export type RestoreServerExtendedMutationHookResult = ReturnType<typeof useRestoreServerExtendedMutation>;
export type RestoreServerExtendedMutationResult = Apollo.MutationResult<RestoreServerExtendedMutation>;
export type RestoreServerExtendedMutationOptions = Apollo.BaseMutationOptions<RestoreServerExtendedMutation, RestoreServerExtendedMutationVariables>;
export const SyncAllServersDocument = gql`
    mutation SyncAllServers($trashedServers: Trashed, $trashedDevices: Trashed) {
  syncAllServers(trashed: $trashedServers) {
    ...ServerBasic
  }
}
    ${ServerBasicFragmentDoc}`;
export type SyncAllServersMutationFn = Apollo.MutationFunction<SyncAllServersMutation, SyncAllServersMutationVariables>;

/**
 * __useSyncAllServersMutation__
 *
 * To run a mutation, you first call `useSyncAllServersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAllServersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAllServersMutation, { data, loading, error }] = useSyncAllServersMutation({
 *   variables: {
 *      trashedServers: // value for 'trashedServers'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useSyncAllServersMutation(baseOptions?: Apollo.MutationHookOptions<SyncAllServersMutation, SyncAllServersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAllServersMutation, SyncAllServersMutationVariables>(SyncAllServersDocument, options);
      }
export type SyncAllServersMutationHookResult = ReturnType<typeof useSyncAllServersMutation>;
export type SyncAllServersMutationResult = Apollo.MutationResult<SyncAllServersMutation>;
export type SyncAllServersMutationOptions = Apollo.BaseMutationOptions<SyncAllServersMutation, SyncAllServersMutationVariables>;
export const SyncServerDocument = gql`
    mutation SyncServer($id: ID!, $trashedDevices: Trashed) {
  syncServer(id: $id) {
    ...ServerBasic
  }
}
    ${ServerBasicFragmentDoc}`;
export type SyncServerMutationFn = Apollo.MutationFunction<SyncServerMutation, SyncServerMutationVariables>;

/**
 * __useSyncServerMutation__
 *
 * To run a mutation, you first call `useSyncServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncServerMutation, { data, loading, error }] = useSyncServerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useSyncServerMutation(baseOptions?: Apollo.MutationHookOptions<SyncServerMutation, SyncServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncServerMutation, SyncServerMutationVariables>(SyncServerDocument, options);
      }
export type SyncServerMutationHookResult = ReturnType<typeof useSyncServerMutation>;
export type SyncServerMutationResult = Apollo.MutationResult<SyncServerMutation>;
export type SyncServerMutationOptions = Apollo.BaseMutationOptions<SyncServerMutation, SyncServerMutationVariables>;
export const SyncServerExtendedDocument = gql`
    mutation SyncServerExtended($id: ID!, $trashedDevices: Trashed) {
  syncServer(id: $id) {
    ...ServerExtended
  }
}
    ${ServerExtendedFragmentDoc}`;
export type SyncServerExtendedMutationFn = Apollo.MutationFunction<SyncServerExtendedMutation, SyncServerExtendedMutationVariables>;

/**
 * __useSyncServerExtendedMutation__
 *
 * To run a mutation, you first call `useSyncServerExtendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncServerExtendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncServerExtendedMutation, { data, loading, error }] = useSyncServerExtendedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useSyncServerExtendedMutation(baseOptions?: Apollo.MutationHookOptions<SyncServerExtendedMutation, SyncServerExtendedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncServerExtendedMutation, SyncServerExtendedMutationVariables>(SyncServerExtendedDocument, options);
      }
export type SyncServerExtendedMutationHookResult = ReturnType<typeof useSyncServerExtendedMutation>;
export type SyncServerExtendedMutationResult = Apollo.MutationResult<SyncServerExtendedMutation>;
export type SyncServerExtendedMutationOptions = Apollo.BaseMutationOptions<SyncServerExtendedMutation, SyncServerExtendedMutationVariables>;
export const ServersAndDevicesDocument = gql`
    query ServersAndDevices($trashedServers: Trashed, $trashedDevices: Trashed) {
  servers(trashed: $trashedServers) {
    ...ServerBasic
  }
}
    ${ServerBasicFragmentDoc}`;

/**
 * __useServersAndDevicesQuery__
 *
 * To run a query within a React component, call `useServersAndDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServersAndDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServersAndDevicesQuery({
 *   variables: {
 *      trashedServers: // value for 'trashedServers'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useServersAndDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ServersAndDevicesQuery, ServersAndDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServersAndDevicesQuery, ServersAndDevicesQueryVariables>(ServersAndDevicesDocument, options);
      }
export function useServersAndDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServersAndDevicesQuery, ServersAndDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServersAndDevicesQuery, ServersAndDevicesQueryVariables>(ServersAndDevicesDocument, options);
        }
export type ServersAndDevicesQueryHookResult = ReturnType<typeof useServersAndDevicesQuery>;
export type ServersAndDevicesLazyQueryHookResult = ReturnType<typeof useServersAndDevicesLazyQuery>;
export type ServersAndDevicesQueryResult = Apollo.QueryResult<ServersAndDevicesQuery, ServersAndDevicesQueryVariables>;
export const ServerDocument = gql`
    query Server($id: ID!, $trashedServer: Trashed = WITH, $trashedDevices: Trashed = WITHOUT) {
  server(id: $id, trashed: $trashedServer) {
    ...ServerExtended
  }
}
    ${ServerExtendedFragmentDoc}`;

/**
 * __useServerQuery__
 *
 * To run a query within a React component, call `useServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      trashedServer: // value for 'trashedServer'
 *      trashedDevices: // value for 'trashedDevices'
 *   },
 * });
 */
export function useServerQuery(baseOptions: Apollo.QueryHookOptions<ServerQuery, ServerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServerQuery, ServerQueryVariables>(ServerDocument, options);
      }
export function useServerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServerQuery, ServerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServerQuery, ServerQueryVariables>(ServerDocument, options);
        }
export type ServerQueryHookResult = ReturnType<typeof useServerQuery>;
export type ServerLazyQueryHookResult = ReturnType<typeof useServerLazyQuery>;
export type ServerQueryResult = Apollo.QueryResult<ServerQuery, ServerQueryVariables>;
export const DeleteUserExperimentDocument = gql`
    mutation DeleteUserExperiment($id: ID!) {
  deleteUserExperiment(id: $id) {
    id
  }
}
    `;
export type DeleteUserExperimentMutationFn = Apollo.MutationFunction<DeleteUserExperimentMutation, DeleteUserExperimentMutationVariables>;

/**
 * __useDeleteUserExperimentMutation__
 *
 * To run a mutation, you first call `useDeleteUserExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserExperimentMutation, { data, loading, error }] = useDeleteUserExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserExperimentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserExperimentMutation, DeleteUserExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserExperimentMutation, DeleteUserExperimentMutationVariables>(DeleteUserExperimentDocument, options);
      }
export type DeleteUserExperimentMutationHookResult = ReturnType<typeof useDeleteUserExperimentMutation>;
export type DeleteUserExperimentMutationResult = Apollo.MutationResult<DeleteUserExperimentMutation>;
export type DeleteUserExperimentMutationOptions = Apollo.BaseMutationOptions<DeleteUserExperimentMutation, DeleteUserExperimentMutationVariables>;
export const RestoreUserExperimentDocument = gql`
    mutation RestoreUserExperiment($id: ID!) {
  restoreUserExperiment(id: $id) {
    id
  }
}
    `;
export type RestoreUserExperimentMutationFn = Apollo.MutationFunction<RestoreUserExperimentMutation, RestoreUserExperimentMutationVariables>;

/**
 * __useRestoreUserExperimentMutation__
 *
 * To run a mutation, you first call `useRestoreUserExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreUserExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreUserExperimentMutation, { data, loading, error }] = useRestoreUserExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreUserExperimentMutation(baseOptions?: Apollo.MutationHookOptions<RestoreUserExperimentMutation, RestoreUserExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreUserExperimentMutation, RestoreUserExperimentMutationVariables>(RestoreUserExperimentDocument, options);
      }
export type RestoreUserExperimentMutationHookResult = ReturnType<typeof useRestoreUserExperimentMutation>;
export type RestoreUserExperimentMutationResult = Apollo.MutationResult<RestoreUserExperimentMutation>;
export type RestoreUserExperimentMutationOptions = Apollo.BaseMutationOptions<RestoreUserExperimentMutation, RestoreUserExperimentMutationVariables>;
export const UpdateUserExperimentDocument = gql`
    mutation UpdateUserExperiment($updateUserExperimentInput: UpdateUserExperimentInput!) {
  updateUserExperiment(input: $updateUserExperimentInput) {
    id
  }
}
    `;
export type UpdateUserExperimentMutationFn = Apollo.MutationFunction<UpdateUserExperimentMutation, UpdateUserExperimentMutationVariables>;

/**
 * __useUpdateUserExperimentMutation__
 *
 * To run a mutation, you first call `useUpdateUserExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserExperimentMutation, { data, loading, error }] = useUpdateUserExperimentMutation({
 *   variables: {
 *      updateUserExperimentInput: // value for 'updateUserExperimentInput'
 *   },
 * });
 */
export function useUpdateUserExperimentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserExperimentMutation, UpdateUserExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserExperimentMutation, UpdateUserExperimentMutationVariables>(UpdateUserExperimentDocument, options);
      }
export type UpdateUserExperimentMutationHookResult = ReturnType<typeof useUpdateUserExperimentMutation>;
export type UpdateUserExperimentMutationResult = Apollo.MutationResult<UpdateUserExperimentMutation>;
export type UpdateUserExperimentMutationOptions = Apollo.BaseMutationOptions<UpdateUserExperimentMutation, UpdateUserExperimentMutationVariables>;
export const UserExperimentsDocument = gql`
    query UserExperiments($first: Int!, $page: Int, $trashed: Trashed, $onlyMine: Boolean = true) {
  userExperiments(
    first: $first
    page: $page
    trashed: $trashed
    onlyMine: $onlyMine
    orderBy: {column: CREATED_AT, order: DESC}
  ) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...UserExperimentBasic
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${UserExperimentBasicFragmentDoc}`;

/**
 * __useUserExperimentsQuery__
 *
 * To run a query within a React component, call `useUserExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExperimentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *      onlyMine: // value for 'onlyMine'
 *   },
 * });
 */
export function useUserExperimentsQuery(baseOptions: Apollo.QueryHookOptions<UserExperimentsQuery, UserExperimentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserExperimentsQuery, UserExperimentsQueryVariables>(UserExperimentsDocument, options);
      }
export function useUserExperimentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExperimentsQuery, UserExperimentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserExperimentsQuery, UserExperimentsQueryVariables>(UserExperimentsDocument, options);
        }
export type UserExperimentsQueryHookResult = ReturnType<typeof useUserExperimentsQuery>;
export type UserExperimentsLazyQueryHookResult = ReturnType<typeof useUserExperimentsLazyQuery>;
export type UserExperimentsQueryResult = Apollo.QueryResult<UserExperimentsQuery, UserExperimentsQueryVariables>;
export const UserExperimentDocument = gql`
    query UserExperiment($id: ID!, $trashed: Trashed = WITH) {
  userExperiment(id: $id, trashed: $trashed) {
    ...UserExperimentExtended
  }
}
    ${UserExperimentExtendedFragmentDoc}`;

/**
 * __useUserExperimentQuery__
 *
 * To run a query within a React component, call `useUserExperimentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExperimentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExperimentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useUserExperimentQuery(baseOptions: Apollo.QueryHookOptions<UserExperimentQuery, UserExperimentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserExperimentQuery, UserExperimentQueryVariables>(UserExperimentDocument, options);
      }
export function useUserExperimentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExperimentQuery, UserExperimentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserExperimentQuery, UserExperimentQueryVariables>(UserExperimentDocument, options);
        }
export type UserExperimentQueryHookResult = ReturnType<typeof useUserExperimentQuery>;
export type UserExperimentLazyQueryHookResult = ReturnType<typeof useUserExperimentLazyQuery>;
export type UserExperimentQueryResult = Apollo.QueryResult<UserExperimentQuery, UserExperimentQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
  updateUser(input: $updateUserInput) {
    ...UserExtended
  }
}
    ${UserExtendedFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const RestoreUserDocument = gql`
    mutation RestoreUser($id: ID!) {
  restoreUser(id: $id) {
    id
  }
}
    `;
export type RestoreUserMutationFn = Apollo.MutationFunction<RestoreUserMutation, RestoreUserMutationVariables>;

/**
 * __useRestoreUserMutation__
 *
 * To run a mutation, you first call `useRestoreUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreUserMutation, { data, loading, error }] = useRestoreUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreUserMutation(baseOptions?: Apollo.MutationHookOptions<RestoreUserMutation, RestoreUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreUserMutation, RestoreUserMutationVariables>(RestoreUserDocument, options);
      }
export type RestoreUserMutationHookResult = ReturnType<typeof useRestoreUserMutation>;
export type RestoreUserMutationResult = Apollo.MutationResult<RestoreUserMutation>;
export type RestoreUserMutationOptions = Apollo.BaseMutationOptions<RestoreUserMutation, RestoreUserMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($updatePasswordInput: UpdatePassword!) {
  updatePassword(input: $updatePasswordInput) {
    status
    message
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      updatePasswordInput: // value for 'updatePasswordInput'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($updateProfileInput: UpdateProfileInput!) {
  updateProfile(input: $updateProfileInput) {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      updateProfileInput: // value for 'updateProfileInput'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UsersDocument = gql`
    query Users($first: Int!, $page: Int, $trashed: Trashed) {
  users(first: $first, page: $page, trashed: $trashed) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...UserBasic
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${UserBasicFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($id: ID, $trashed: Trashed = WITH) {
  user(id: $id, trashed: $trashed) {
    ...UserExtended
  }
}
    ${UserExtendedFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ProfileDocument = gql`
    query Profile($id: ID) {
  user(id: $id) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($registerInput: RegisterInput) {
  register(input: $registerInput) {
    status
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      registerInput: // value for 'registerInput'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($loginInput: LoginInput) {
  login(input: $loginInput) {
    access_token
    refresh_token
    expires_in
    token_type
    user {
      ...AuthenticatedUser
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LdapLoginDocument = gql`
    mutation LdapLogin($ldapLoginInput: LdapLoginInput) {
  ldapLogin(input: $ldapLoginInput) {
    access_token
    refresh_token
    expires_in
    token_type
    user {
      ...AuthenticatedUser
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export type LdapLoginMutationFn = Apollo.MutationFunction<LdapLoginMutation, LdapLoginMutationVariables>;

/**
 * __useLdapLoginMutation__
 *
 * To run a mutation, you first call `useLdapLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLdapLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ldapLoginMutation, { data, loading, error }] = useLdapLoginMutation({
 *   variables: {
 *      ldapLoginInput: // value for 'ldapLoginInput'
 *   },
 * });
 */
export function useLdapLoginMutation(baseOptions?: Apollo.MutationHookOptions<LdapLoginMutation, LdapLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LdapLoginMutation, LdapLoginMutationVariables>(LdapLoginDocument, options);
      }
export type LdapLoginMutationHookResult = ReturnType<typeof useLdapLoginMutation>;
export type LdapLoginMutationResult = Apollo.MutationResult<LdapLoginMutation>;
export type LdapLoginMutationOptions = Apollo.BaseMutationOptions<LdapLoginMutation, LdapLoginMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshTokenInput: RefreshTokenInput) {
  refreshToken(input: $refreshTokenInput) {
    access_token
    refresh_token
    expires_in
    token_type
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshTokenInput: // value for 'refreshTokenInput'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SocialLoginDocument = gql`
    mutation SocialLogin($socialLoginInput: SocialLoginInput!) {
  socialLogin(input: $socialLoginInput) {
    access_token
    refresh_token
    expires_in
    token_type
    user {
      ...AuthenticatedUser
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export type SocialLoginMutationFn = Apollo.MutationFunction<SocialLoginMutation, SocialLoginMutationVariables>;

/**
 * __useSocialLoginMutation__
 *
 * To run a mutation, you first call `useSocialLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialLoginMutation, { data, loading, error }] = useSocialLoginMutation({
 *   variables: {
 *      socialLoginInput: // value for 'socialLoginInput'
 *   },
 * });
 */
export function useSocialLoginMutation(baseOptions?: Apollo.MutationHookOptions<SocialLoginMutation, SocialLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SocialLoginMutation, SocialLoginMutationVariables>(SocialLoginDocument, options);
      }
export type SocialLoginMutationHookResult = ReturnType<typeof useSocialLoginMutation>;
export type SocialLoginMutationResult = Apollo.MutationResult<SocialLoginMutation>;
export type SocialLoginMutationOptions = Apollo.BaseMutationOptions<SocialLoginMutation, SocialLoginMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
    message
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;